import { navigate, graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import tinycolor from "tinycolor2"
import { useSwipeable } from "react-swipeable"
import styled from "@emotion/styled"
import exifParser from 'exif-parser';
import { useEffect, useState, useRef } from "react"
import { BsInfoCircle } from "react-icons/bs";


import { IconWrapper, Overlay, ThreeThirdsGrid, Third, Wrapper } from "../../utils/styles"
import { CloseIcon } from "gatsby-theme-photography-portfolio/src/components/ui/icons"
import { useKeyPress } from "gatsby-theme-photography-portfolio/src/utils/hooks"
import Seo from "../../components/seo"
import Layout from "gatsby-theme-photography-portfolio/src/layouts"

import { leftIcon, gridIcon, rightIcon } from "./icons"
import { space } from "../../utils/styles"
const slugify = require("slugify")

const PhotoPage = ({ data, pageContext }) => {
  const { previousImage, nextImage, name, categoryName } = pageContext

  //let description = "";

  const [description, setDescription] = useState('');


  const overlayInfoRef = useRef();
  const handleClickInfo = () => {
    const overlayInfo = overlayInfoRef.current
    console.log("overlayInfo", overlayInfo);
    overlayInfo.classList.toggle('open');

  };

  const handleClickExit = () => {
    const overlayInfo = overlayInfoRef.current
    if (overlayInfo.classList.contains('open')) {
      overlayInfo.classList.remove('open');
    }

  };

  let indexImage = null;
  data.allContentfulWorks.edges[0].node.media.forEach((element, index) => {
    //console.log("elementelement", element, pageContext);
    if (element.id == pageContext.idImage) {
      indexImage = index;
    }
  });

  const imageUrl = data.allContentfulWorks.edges[0].node.media[indexImage].file.url;

  useEffect(() => {
    // Qui inserisci il codice per ottenere il valore di description
    // Ad esempio, potrebbe essere una chiamata API o un calcolo
    fetch(imageUrl)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const parser = exifParser.create(buffer);
        const result = parser.parse();

        setDescription(result.tags.ImageDescription);
        console.log("result", result.tags.ImageDescription);
      });

  }, [imageUrl]); // Passa un array vuoto come secondo argomento per eseguire l'effetto solo al primo render

  /* useEffect(() => {
    fetch(imageUrl)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        const parser = exifParser.create(buffer);
        const result = parser.parse();
        description = result.tags.ImageDescription;
        console.log("result", result.tags.ImageDescription);
      });
  }, [imageUrl]); */

  const image = getImage(data.allContentfulWorks.edges[0].node.media[indexImage].gatsbyImageData)

  image.backgroundColor = "#000000";
  const title = data.allContentfulWorks.edges[0].node.media[indexImage].title
  const isLight = tinycolor(image.backgroundColor).isLight()




  const goToPreviousImage = () => navigate(previousImage)
  const goToCategory = () =>
    navigate(`/${categoryName}/#${"photo-0" + indexImage}`)
  const goToNextImage = () => navigate(nextImage)

  useKeyPress("ArrowRight", goToNextImage)
  useKeyPress("ArrowLeft", goToPreviousImage)
  useKeyPress("Escape", goToCategory)

  const handlers = useSwipeable({
    onSwipedRight: goToNextImage,
    onSwipedLeft: goToPreviousImage,
  })

  const ThreeThirdsGrid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  gap: 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: row dense;
`

  const Third = styled.div`
  height: 100%;
  width: 100%;
  ${({ left, isLight }) =>
      left ? `cursor: url("${leftIcon(isLight)}") 16 16, pointer;` : null}
  ${({ middle, isLight }) =>
      middle ? `cursor: url("${gridIcon(isLight)}") 16 16, pointer;` : null}
  ${({ right, isLight }) =>
      right ? `cursor: url("${rightIcon(isLight)}") 16 16, pointer;` : null}
`

  const Wrapper = styled.div`
  height: 100vh;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  align-items: center;
  overflow-x: hidden;
 
`

  const IconWrapper = styled.div`
  position: absolute;
  z-index: 11;
  right: 0;
  top: 0;
  padding: ${space[4]};

  svg {
    cursor: pointer;
  }
`

  const IconWrapperInfo = styled.div`
  position: absolute;
  z-index: 11;
  left: 0;
  top: 0;
  padding: ${space[4]};

  svg {
    cursor: pointer;
  }
`

  const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
`

  const InfoOverlay = styled.div`
position: fixed;
height: 100%;
width:100%;
z-index: 11;
transition: all 0.5s ease-in-out;
overflow: hidden;
left:-200%;
background: rgba(0,0,0,0.85);
display: flex;
justify-content: center;
align-items: center;


&.open {
  left: 0;
  
}

 p {
  margin: auto;
  font-family: Roboto, sans-serif;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 300;
 
  height: 100%;
  
  color: #fff;
  width: 100%;
 }
`


  return (
    <Layout>
      <Seo title={title} thumbnail={image.images.fallback.src} />
      {/* image.backgroundColor */}
      <Wrapper backgroundColor={image.backgroundColor} >
        <IconWrapper>
          <Link to={`/${categoryName}`}>
            <CloseIcon color={isLight ? "black" : "white"} />
          </Link>
        </IconWrapper>
        <IconWrapperInfo onClick={handleClickInfo}>
          <BsInfoCircle color="white" size={30} />
        </IconWrapperInfo>

        <Overlay {...handlers}>
          <ThreeThirdsGrid>
            <Third left onClick={goToPreviousImage} isLight={isLight} />
            <Third middle onClick={goToCategory} isLight={isLight} />
            <Third right onClick={goToNextImage} isLight={isLight} />
          </ThreeThirdsGrid>
        </Overlay>
        <InfoOverlay ref={overlayInfoRef} onClick={handleClickExit}>
          <div>
            <p>{description}</p>
          </div>
        </InfoOverlay>
        <GatsbyImage
          image={image}
          alt={"title"}
          objectFit="contain"
          style={{ maxHeight: "100%" }}
        />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query ($idCat: String!) {
    allContentfulWorks(filter: {id: {eq: $idCat}}) {
      edges {
        node {
          media {
            gatsbyImageData
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default PhotoPage
